import React from 'react';

import styles from './MoreButton.module.scss';
import { Link } from 'gatsby';

const MoreButton = ({ text, url }) => (
  <Link className={styles.button} to={url}>{text}</Link>
);

export default MoreButton;
