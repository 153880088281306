import React from 'react';
import { Link } from 'gatsby';

import styles from './Hero.module.scss';

const Hero = ({ byline, image, text, to }) => {
  return (
    <Link to={to} className={styles.wrapper} style={{backgroundImage: `url(${image})`}}>
      <div className={styles.container}>
        <h1 className={styles.header}>{text}</h1>
        <h2 className={styles.byline}>{byline}</h2>
      </div>
    </Link>
  );
};

Hero.displayName = 'Hero';

export default Hero;
