import React from 'react';
import { graphql } from 'gatsby';

import filterCollections from '../utils/data/filterCollections';
import removeNode from '../utils/data/removeNode';

import CollectionList from '../components/collections/CollectionList/CollectionList';
//import PostList from '../components/Posts/PostList/PostList';
import Hero from '../components/furniture/Hero/Hero';
import Layout from '../components/furniture/Layout/Layout';
import generateTitle from '../utils/generateTitle';
import Profile from '../components/furniture/Profile/Profile';
import TagList from '../components/tags/TagList/TagList';
import MoreButton from '../components/furniture/MoreButton/MoreButton';

import heroImage from './homepage.jpg';

const IndexPage = ({ data, location }) => {
  const {
    collections: { edges: collections },
    tags: { edges: tags },
  } = data;

  const pageTitle = generateTitle('Home');

  return (
    <Layout
      location={location}
      hero={
        <Hero
          to="/"
          image={heroImage}
          text="Track your collections"
          byline="Coming soon!"
        />
      }
      pageTitle={pageTitle}
    >
      <CollectionList collections={filterCollections(collections, 'none', 'none', 8)} section="collections" title="Latest Collections" />

      <TagList tags={removeNode(tags).slice(0, 14)} title="Popular Tags" />

      <CollectionList collections={filterCollections(collections, 'type', 'graphic novels', 4)} section="collections" title="Latest Graphic Novel Collections" />

      <CollectionList collections={filterCollections(collections, 'tags', 'LEGO', 4)} section="collections" title="Latest LEGO Collections" />

      <MoreButton text="View all collections" url="/collections" />
      {/* <PostList posts={posts}/> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query contentQuery {
    collections: allAirtable(
      sort: { fields: [data___date], order: DESC }
      filter: { table: { eq: "collections" } }
    ) {
      edges {
        node {
          data {
            title
            slug
            image {
                localFiles {
                    childImageSharp {
                        fluid(maxWidth: 600, quality: 40) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
            type
            tags {
                data {
                    title
                    slug
                }
            }
          }
        }
      }
    }
    tags: allAirtable(
      sort: { fields: [data___count], order: DESC }
      filter: { table: { eq: "tags" } }
    ) {
      edges {
        node {
          data {
            title  
            slug
            count
          }
        }
      }
    }
  }
`;

export default IndexPage;
